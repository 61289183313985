const accordionGroups = document.querySelectorAll('.accordion-group');
const faqItems = document.querySelectorAll('.faq-item');

const toggleItem = (group, item) => {
  item.addEventListener('click', () => {
    if (item.getAttribute('data-state') === 'active') {
      item.removeAttribute('data-state');
    } else {
      item.setAttribute('data-state', 'active');
      group.forEach((otherItem) => {
        if (
          otherItem !== item &&
          otherItem.getAttribute('data-state') === 'active'
        ) {
          otherItem.removeAttribute('data-state');
        }
      });
    }
  });
};

accordionGroups.forEach((group) => {
  const accordionItems = group.querySelectorAll('.accordion-item');

  accordionItems.forEach((item) => {
    toggleItem(accordionItems, item);
  });
});

faqItems.forEach((item) => {
  toggleItem(faqItems, item);
});

const defaultsSwiper = document.querySelectorAll(
  '.default__content-bloc__swiper'
);

defaultsSwiper?.forEach((item) => {
  const next = item.querySelector('.swiper-button-next');
  const prev = item.querySelector('.swiper-button-prev');

  const swiper = new Swiper(item, {
    slidesPerView: 1,
    autoHeight: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    keyboard: {
      enabled: true,
    },
  });
});

const biensGallerySlider = document.querySelectorAll('.bien-item__slider');

biensGallerySlider?.forEach((item) => {
  const next = item.querySelector('.swiper-button-next');
  const prev = item.querySelector('.swiper-button-prev');

  const swiper = new Swiper(item, {
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    loop: true,
  });
});

const carrouselsBiensDk = document.querySelectorAll(
  '.carrousel-biens-dk__slider'
);

carrouselsBiensDk?.forEach((item) => {
  const next = item.querySelector('.swiper-button-next');
  const prev = item.querySelector('.swiper-button-prev');

  const swiper = new Swiper(item.querySelector('.swiper'), {
    slidesPerView: 3,
    spaceBetween: 20,
    navigation: {
      nextEl: next,
      prevEl: prev,
    },
    keyboard: {
      enabled: true,
    },

    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
});

const carrouselsBiensFl = document.querySelectorAll(
  '.carrousel-biens-fl.swiper'
);

carrouselsBiensFl?.forEach((item) => {
  const next = item.querySelector('.swiper-button-next');
  const prev = item.querySelector('.swiper-button-prev');

  const swiper = new Swiper(item, {
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    freeMode: true,
    grabCursor: true,
    keyboard: {
      enabled: true,
    },
  });
});
